<template>
  <div class="flex flex-column align-items-center gap-4 pt-35vh">
    <a href="https://menu.noder.tw/brands/08ZmJ0lw1g/delivery-register" target="_blank" rel="noopener noreferrer"
      class="p-button p-button-purpply w-12rem text-center">外送點餐</a>
    <a href="https://noder.tw:4000/login?b=anh" target="_blank" rel="noopener noreferrer"
      class="p-button p-button-purpply w-12rem text-center">會員系統</a>
  </div>
</template>

<style scoped lang="scss">
.pt-35vh {
  padding-top: 35vh;
}
</style>
